import { Fragment, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import reportWebVitals from "./reportWebVitals";
import { publicRoutes } from "./routes";
import DefaultLayout from "./layouts/DefaultLayout";
import { PreviousStateProvider } from "./contexts/PreviousStateContext";
import Fonts from "./theme/foundations/fonts";
import LoadingSpinner from "./components/LoadingSpinner";
import { ChakraProvider } from "@chakra-ui/react";

export default function App() {

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  return (
    <BrowserRouter>
        <ChakraProvider>
          <Fonts />
          <PreviousStateProvider>
            <Suspense fallback={<LoadingSpinner />}>
            <ScrollToTop />
              <Routes>
                {publicRoutes.map((route) => {
                  const Page = route.component;
                  let Layout = DefaultLayout;

                  if (route.layout === null) {
                    Layout = Fragment;
                  } else if (route.layout) {
                    Layout = route.layout;
                  }

                  return (
                    <Route
                      key={uuid()}
                      path={route.path}
                      element={
                          <Layout>
                            <Page />
                          </Layout>
                      }
                    />
                  );
                })}
              </Routes>
            </Suspense>
          </PreviousStateProvider>
        </ChakraProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals(console.log);
