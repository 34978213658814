import { lazy } from "react";

// Pages
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Projects = lazy(() => import("./pages/Projects"));
const Services = lazy(() => import("./pages/Services"));

// Layouts
const DefaultLayout = lazy(() => import("./layouts/DefaultLayout"));
const InvalidLayout = lazy(() => import("./layouts/InvalidLayout"));

export const routePaths = {
  home: "/",
  about: "/about",
  projects: "/projects",
  services: "/services",
  contact: "/#contact"
};

const publicRoutes = [
  { path: routePaths.home, component: Home, layout: DefaultLayout },
  { path: routePaths.about, component: About, layout: DefaultLayout },
  { path: routePaths.projects, component: Projects, layout: DefaultLayout },
  { path: routePaths.services, component: Services, layout: DefaultLayout },
  { path: routePaths.contact, component: Home, layout: DefaultLayout },
  { path: "*", component: Home, layout: InvalidLayout }
];

export { publicRoutes };
