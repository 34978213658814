import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
        /* latin */
        @font-face {
            fontFamily: "Montserrat Thin",
            src: url("./fonts/Montserrat-Thin.ttf") format("ttf"),
            fontWeight: "100",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        /* latin */
        @font-face {
            fontFamily: "Montserrat Light",
            src: url("./fonts/Montserrat-Light.ttf") format("ttf"),
            fontWeight: "300",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        /* latin */
        @font-face {
            fontFamily: "Montserrat Regular",
            src: url("./fonts/Montserrat-Regular.ttf") format("ttf"),
            fontWeight: "400",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        /* latin */
        @font-face {
            fontFamily: "Montserrat Medium",
            src: url("./fonts/Montserrat-Medium.ttf") format("ttf"),
            fontWeight: "500",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        /* latin */
        @font-face {
            fontFamily: "Montserrat Bold",
            src: url("./fonts/Montserrat-Bold.ttf") format("ttf"),
            fontWeight: "700",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        /* latin */
        @font-face {
            fontFamily: "Montserrat Black",
            src: url("./fonts/Montserrat-Black.ttf") format("ttf"),
            fontWeight: "900",
            fontStyle: "normal",
            fontDisplay: "swap",
        }
        `}
  />
);

export default Fonts;
