import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Flex,
  Container,
  Link,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue,
  Text,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { routePaths } from "../routes";

import logo from "../resources/images/logo.svg";

export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const location = useLocation();

  const [navigationLinks] = useState([
    { text: "Home", path: routePaths.home },
    { text: "About", path: routePaths.about },
    { text: "Projects", path: routePaths.projects },
    { text: "Services", path: routePaths.services },
    { text: "Contact", path: routePaths.contact },
  ]);

  useEffect(() => {
    const id = location.hash.replace("#", "");
    const element = document.getElementById(id);
    if (!element) return;

    let hasScrolled = false;

    const observer = new MutationObserver(() => {
      if (
        document.readyState === "complete" &&
        !hasScrolled &&
        element.offsetParent !== null
      ) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          hasScrolled = true;
        }, 100);
      }
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [location.hash]);

  const NavigationLink = ({
    text,
    path,
    closeMenu,
  }: {
    text: string;
    path: string;
    closeMenu?: () => void;
  }) => {
    const location = useLocation();
    const isActive =
      location.pathname === path || location.pathname + location.hash === path;

    return (
      <Link
        //@ts-ignore
        as={(props) => (
          <NavLink
            {...props}
            onClick={() => {
              closeMenu && closeMenu();
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            style={{
              fontWeight: isActive ? "bold" : "normal",
            }}
          />
        )}
        fontSize="lg"
        color="#ffffff"
        to={path}
      >
        {text}
      </Link>
    );
  };

  const MobileNavigation = () => (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Navigation</DrawerHeader>
        <DrawerBody bg="#0c3c60">
          <Flex gap="1rem" direction="column" alignItems="start">
            {navigationLinks.map((link, index) => (
              <NavigationLink
                key={index}
                text={link.text}
                path={link.path}
                closeMenu={onClose}
              />
            ))}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <Flex direction="column" minH="100vh">
      <Box
        as="header"
        top={0}
        w="100%"
        shadow="md"
        bg="#0c3c60"
        position="sticky"
        zIndex="docked"
      >
        <Container maxW="container.xxl">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            pb={isMobile ? 3 : 6}
            pt={3}
          >
            {!isMobile && (
              <Flex direction="column">
                <Link href={routePaths.home}>
                  <Image w="100%" h="auto" src={logo} />
                </Link>
                {/*
                <Heading fontWeight="light" size="lg" color="#ffffff">
                  Brüggemann
                </Heading>
                <Heading fontWeight="light" size="lg" color="#ffffff">
                  Chassis & Composites
                </Heading>
                */}
              </Flex>
            )}

            {isMobile ? (
              <IconButton
                aria-label="Open Menu"
                icon={<HamburgerIcon />}
                onClick={onOpen}
                size="lg"
                color="#ffffff"
                variant="transparent"
                position="absolute"
                right="8px"
              />
            ) : (
              <Flex direction="row" alignItems="flex-end" gap="2rem">
                {navigationLinks.map((link, index) => (
                  <NavigationLink
                    key={index}
                    text={link.text}
                    path={link.path}
                  />
                ))}
              </Flex>
            )}

            {isMobile && (
              <Link href={routePaths.home}>
                <Image w="50%" h="auto" src={logo} />
              </Link>
              /*
                <Heading fontWeight="light" size={{base: "md", md: "lg" }} color="#ffffff" textAlign="center">
                  Brüggemann Chassis & Composites
                </Heading>
              */
            )}
          </Flex>
        </Container>
      </Box>

      {isMobile && <MobileNavigation />}

      <Box flex={1}>{children}</Box>

      <Box bg="#072136" w="100%">
        <Container maxW="container.xxl" py={4}>
          <Flex
            justify="space-between"
            color="#ffffff"
            gap={isMobile ? 6 : 0}
            direction={isMobile ? "column" : "row"}
          >
            <Box flex="1" textAlign="center">
              <Heading
                fontWeight="light"
                size="md"
                textTransform="uppercase"
                mb={4}
              >
                Felix Brüggemann
              </Heading>
              <Flex direction="column" gap={2}>
                <Text>Address: Theanoltestr. 35, 49356 Diepholz</Text>
                <Text>Email: brueggemann@chassis-composites.de</Text>
                <Text>Tel: +49 162 7997200</Text>
              </Flex>
            </Box>
            <Box
              flex="1"
              textAlign="center"
              borderY={isMobile ? "1px solid white" : "none"}
            >
              <Heading
                fontWeight="light"
                size="md"
                textTransform="uppercase"
                mt={isMobile ? 4 : 0}
                mb={4}
              >
                Menu
              </Heading>
              <Flex direction="column" gap={2} mb={isMobile ? 4 : 0}>
                {navigationLinks.map((link, index) => (
                  <NavigationLink
                    key={index}
                    text={link.text}
                    path={link.path}
                  />
                ))}
              </Flex>
            </Box>
            <Box flex="1" textAlign="center">
              <Heading
                fontWeight="light"
                size="md"
                textTransform="uppercase"
                mb={4}
              >
                Social
              </Heading>
              <Flex direction="column" gap={2}>
                <Link
                  href="https://www.linkedin.com/in/felix-brueggemann"
                  isExternal
                >
                  <IconButton
                    aria-label="LinkedIn"
                    icon={<FaLinkedinIn />}
                    fontSize="2xl"
                    color="#0A66C2"
                    variant="transparent"
                  />
                </Link>
                <Text>
                  © {new Date().getFullYear()} by Brüggemann Chassis &
                  Composites
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box zIndex="docked" position="fixed" bottom="20px" right="20px">
        <Link href="https://wa.me/1627997200" isExternal>
          <IconButton
            icon={<FaWhatsapp />}
            isRound
            boxSize={12}
            fontSize="3xl"
            colorScheme="whatsapp"
            aria-label="Kontaktieren Sie mich auf WhatsApp"
          />
        </Link>
      </Box>
    </Flex>
  );
}
