import React, { createContext, useContext, useState } from "react";

const PreviousStateContext = createContext();
export const usePreviousState = () => useContext(PreviousStateContext);

export const PreviousStateProvider = ({ children }) => {
  const [previousState, setPreviousState] = useState(null);

  return (
    <PreviousStateContext.Provider value={{ previousState, setPreviousState }}>
      {children}
    </PreviousStateContext.Provider>
  );
};
